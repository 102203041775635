* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
}

body {
  min-height: 100vh;
  overflow-x: hidden;
  background: rgb(237, 239, 241);
/* background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(250, 250, 250) 51%, rgb(255, 255, 255) 100%); */
}

.Sidebar {
  background: #19c3e6;
  padding: -10px 0 10px 10px;
  /* border-right: 0px solid rgb(241, 13, 13); */
  width: 300px; 
  /* padding-left: 37px; */
  overflow: hidden; 
  height: 100%;
}

.navigation {
  position: fixed; 
  width: 300px;
  height: 100%;
  background: #19c3e6 ;
  transition: 0.5s;
  overflow: hidden;
}
.navigation ul {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
} 

  

ul li {
  list-style: none;
  padding: 10px;
  padding-right: 0;
  color: white; 
  font-size: 13px;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;
  transition: 0.5s;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  /* text-decoration: none; */
}

ul li :hover{
  text-decoration: none;
}



ul li.active:before {
  content: '';
  position: absolute;
  top: -20px; 
  right: 0px;
  width: 20px;
  height: 20px;
  border-radius: 70%;
  box-shadow: 10px 10px 0 rgb(248, 248, 248);
 text-decoration: none;
}

.Sidebar .submenu a {
  color: white;
  text-decoration: none;
}

.Sidebar ul a {
  color: white;
  text-decoration: none;
}




ul li.active:after {
  content: '';
  position: absolute;
  bottom: -20px;
  right: 0px;
  width: 20px;
  height: 20px;
  background: transparent;
  border-radius: 50%;
  box-shadow: 10px -10px 0 rgb(252, 252, 252);
list-style: none;
}

ul li.active {
  background: rgb(249, 250, 250);
  background: linear-gradient(90deg, rgb(253, 253, 253) 0%, rgb(250, 250, 250) 51%, rgb(255, 255, 255) 100%);  color:  #19c3e6;
  font-weight: bold;
  line-height: normal;
}



img {
  margin-left: 25px;
}
 

.navimg {
  color: white;
  border-radius: 5px;
}

a {
  text-decoration: none;
}


.cardone{
  background: linear-gradient(90deg, rgba(56, 49, 58, 0) 0%, rgba(0, 102, 255, 0.45) 100%);
}


.heading {
  font-weight: bold;
  font-family: sans-serif;
} 

.tracking-input {
  width: 80%;
}


.d-flex {
  display: flex;
  
} 

.justify-content-between {
  justify-content: space-between;
} 

.align-items-center {
  align-items: center;
}
    

 td, th {
  border: 1px solid #6a5858;
  padding: 10px;
}

th{
  text-align: center;
}



.subtotal-last{
  margin-top:100px;
  background: #000;
  height: 1px;
}

.maintable{
  max-width: 100%;
  overflow-x: auto;
 
}

 ul a.active {
text-decoration: none;
}

.dolarone{
  color: #0983c9;
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: #009AF0;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.outstanding{
  color: #01ACFF;
font-family: Poppins;
font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: normal;
}













