.payment-form {
    display: flex;
    flex-direction: column;
    max-width: 400px; 
    width: 500px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .payment-form h2 {
    margin-bottom: 20px;
    text-align: center;
    color: #333;
  }
  
  .amount-input {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc; 
    border-radius: 4px;
    font-size: 16px;
  }
  
  .card-element {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 15px;
    font-size: 16px;
  }
  
  .submit-button {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .message {
    margin-top: 15px;
    text-align: center;
    color: green;
  }
  
  
  
  .card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
  }
  
  .card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .card-title {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .card-text {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .btn-primary {
    background-color: #007bff;
    border: none;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  
  
  /* sucess page */
  .payment-done-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* background-color: #f9f9f9; */
  }
  
  .payment-done-content {
    text-align: center;
    /* background: white; */
    padding: 40px;
    border-radius: 10px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  }
  
  .checkmark-icon {
    margin-bottom: 20px;
  }
  
  h1 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 16px;
    color: #666;
    margin: 5px 0;
  }
  
  .go-back-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #6c63ff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .go-back-button:hover {
    background-color: #5a54e0;
  }
  
  /* PlanCustomizer.css */
 

  
 
  
 
  
  /* .note {
    font-size: 0.9em;
    color: #555;
    margin-bottom: 20px;
  }
   */
  .additional-options .option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .option-controls {
    display: flex;
    align-items: center;
  }
  
  .option-controls button {
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 50%;
    background-color: #ddd;
    font-size: 1em;
    cursor: pointer;
  }
  
  .option-controls button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .option-controls span {
    margin: 0 10px;
    font-weight: bold;
  }
  
  .cost {
    font-size: 0.85em;
    color: #555;
    margin-top: 5px;
    margin-left: 40px;
  }
  
  .purchase-summary {
    /* width: 35%; */
    padding: 20px;
    /* border-left: 1px solid #ddd; */
    /* padding-left: 40px; */
    background-color: #f9f9f9;
  }

  /* Add this to your CSS file */
/* Add this to your CSS file */
.slider-container {
  width: 300px; /* Adjust width as needed */
  margin: 20px;
}

/* Style the track (filled part of the slider) */
.rc-slider-track {
  background-color: var(--brand-iris-purple-500); /* Replace with your color variable or hex code */
  height: 10px; /* Adjust height */
}

/* Style the handle (thumb) */
.rc-slider-handle {
  border-color: var(--brand-iris-purple-500); /* Replace with your color variable or hex code */
  height: 24px;
  width: 24px;
  margin-top: -7px; /* Adjust positioning */
  background-color: white;
  border-width: 2px; /* Adjust border thickness */
}

/* Style the rail (unfilled part of the slider) */
.rc-slider-rail {
  background-color: #d9d9d9; /* Default rail color */
  height: 10px; /* Adjust height */
}

/* Style the marks */
.rc-slider-mark-text {
  font-size: 14px; /* Adjust font size */
  color: #333; /* Adjust text color */
}

/* Choose a plan  */
.card {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.card .card-title {
  font-weight: bold;
  font-size: 1.5rem;
}

.card .card-text {
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.card .btn {
  margin-top: 15px;
}

.btn-primary {
  background-color: #6c63ff;
  border: none;
}

.btn-primary:hover {
  background-color: #5b54e0;
}

.border-active {
  border: 2px solid green !important;
}


/*  */


  
  
  
  /* downloadinvoice css */

  .invoice-container {
    width: 80%;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    margin-top: 50px;
    color: #333;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  
  .logo h2 {
    margin: 0;
  }
  
  .logo .brand {
    color: teal;
  }
  
  .order-info {
    text-align: right;
  }
  
  .order-info h3 {
    margin: 0;
  }
  
  .customer-message {
    margin-bottom: 20px;
  }
  
  .customer-message .highlight {
    color: red;
  }
  
  .item-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .item-table th, .item-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
  }
  
  .item-table th {
    background-color: #f9f9f9;
  }
  
  .total-info p {
    text-align: right;
  }
  
  .info-section {
    display: flex;
    justify-content: space-between;
  }
  
  .info-block {
    width: 22%;
  }
  
  .info-block h4 {
    border-bottom: 2px solid #ccc;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  
  .info-block p {
    margin: 5px 0;
  }
  
  
  
  /* new design plan css */
  .payment-form {
    display: flex;
    flex-direction: column;
    max-width: 400px; 
    width: 500px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .payment-form h2 {
    margin-bottom: 20px;
    text-align: center;
    color: #333;
  }
  
  .amount-input {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc; 
    border-radius: 4px;
    font-size: 16px;
  }
  
  .card-element {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 15px;
    font-size: 16px;
  }
  
  .submit-button {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .message {
    margin-top: 15px;
    text-align: center;
    color: green;
  }
  
  
  
  .card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
  }
  
  .card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .card-title {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .card-text {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .btn-primary {
    background-color: #007bff;
    border: none;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  
  
  .payment-done-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
   background-color: #f9f9f9;
  }
  
  .payment-done-content {
    text-align: center;
     background: white; 
    padding: 40px;
    border-radius: 10px;
     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  }
  
  .checkmark-icon {
    margin-bottom: 20px;
  }
  
  h1 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 16px;
    color: #666;
    margin: 5px 0;
  }
  
  .go-back-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #6c63ff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .go-back-button:hover {
    background-color: #5a54e0;
  }
  
  /* .note {
    font-size: 0.9em;
    color: #555;
    margin-bottom: 20px;
  }
   */
  .additional-options .option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .option-controls {
    display: flex;
    align-items: center;
  }
  
  .option-controls button {
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 50%;
    background-color: #ddd;
    font-size: 1em;
    cursor: pointer;
  }
  
  .option-controls button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .option-controls span {
    margin: 0 10px;
    font-weight: bold;
  }
  
  .cost {
    font-size: 0.85em;
    color: #555;
    margin-top: 5px;
    margin-left: 40px;
  }
  
  .purchase-summary {
    width: 35%; 
    padding: 20px;
    border-left: 1px solid #ddd; 
    padding-left: 40px; 
    background-color: #f9f9f9;
  }

.slider-container {
  width: 300px;
  margin: 20px;
}

.rc-slider-track {
  background-color: var(--brand-iris-purple-500); 
  height: 10px; 
}

.rc-slider-handle {
  border-color: var(--brand-iris-purple-500); 
  height: 24px;
  width: 24px;
  margin-top: -7px; 
  background-color: white;
  border-width: 2px; 
}

.rc-slider-rail {
  background-color: #d9d9d9; 
  height: 10px;
}
.rc-slider-mark-text {
  font-size: 14px; 
  color: #333; 
}

.card {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.card .card-title {
  font-weight: bold;
  font-size: 1.5rem;
}

.card .card-text {
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.card .btn {
  margin-top: 15px;
}

.btn-primary {
  background-color: #6c63ff;
  border: none;
}

.btn-primary:hover {
  background-color: #5b54e0;
}

.border-active {
  border: 2px solid green !important;
}




  
  
  
  
  
  