.payment-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 30px;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
  }
  
  .nav-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .nav-steps {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }
  
  .nav-step {
    display: flex;
    align-items: center;
    margin-left: 20px;
    font-size: 14px;
    color: #000; 
    transition: color 0.3s;
  }
  
  .step-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ddd; 
    margin-right: 10px;
    position: relative;
  }
  
  .step-number {
    font-size: 16px;
    color: #fff;
  }
  
  .icon-check {
    font-size: 16px;
    color: #fff;
  }
  
  .nav-step.active .step-icon {
    background-color: #6a0dad; 
    animation: pulse 1s infinite; 
  }
  
  .nav-step.active span:last-child {
    font-weight: bold;
  }
  
  .nav-step.completed .step-icon {
    background-color: #6a0dad;
  }
  
  .nav-step.inactive .step-icon {
    background-color: #ddd; 
  }
  
  .nav-step.inactive span:last-child {
    color: #000; 
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.1);
      opacity: 0.8;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  