.form-group {
    margin-bottom: 15px;
    /* display: flex; */
    margin-top: 10px;
}

.form-control {
    display: inline-block;
    width:200px;
    padding: 5px 10px;
    /* margin-left: 10px; */
}

input[type="date"] {
    width: 150px;
}

input[type="checkbox"] {
    margin-left: 10px;
}