.checkout-container {
  display: flex;
  flex-direction: row;
  /* max-width: 400px; */
  margin: 0 auto;
  padding: 30px;
  gap: 100px;
  font-family: 'Arial', sans-serif;
}

.subscription-details {
  margin-bottom: 20px;
  width: 400px;
}

.subscription-details h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.price {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.plan-summary {
  margin-bottom: 20px;
}

.plan-summary p {
  margin: 5px 0;
}

.price-breakdown {
  border-top: 1px solid #ccc;
  padding-top: 10px;
}

.breakdown-item {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}

.breakdown-item.total {
  font-weight: bold;
  margin-top: 10px;
}

.payment-form {
  display: flex;
  flex-direction: column;
}

.form-input {
  margin-bottom: 15px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.card-element-wrapper {
  margin-bottom: 15px;
}

.card-element-wrapper label {
  margin-bottom: 5px;
  display: block;
}

.card-input-container {
  display: flex;
  align-items: center;
  position: relative;
}

.card-element {
  flex: 1;
  padding-right: 40px; /* Adjust padding as needed */
}

.containers{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-element-icons {
  position: absolute;
  right: 10px;
  display: flex;
  top:10px;
  align-items: center;
}

/* .card-element-icon {
  position: absolute;
  right: 10px;
  display: flex;
  top:10px;
  align-items: center;
} */

.card-icon {
  width: 30px; /* Adjust size as needed */
  margin-left: 5px; /* Adjust spacing as needed */
}

.card-element-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.half-width {
  width: 48%;
}

.business-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.business-checkbox input {
  margin-right: 10px;
}
.font-size-small{
  font-size: small;
}

.subscribe-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.subscribe-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.terms {
  font-size: 12px;
  color: #666;
  margin-top: 10px;
}

.customer-form {
  padding: 0px;
  margin-top: 50px;
}

.form-group {
  margin-bottom: 10px;
}

.form-control {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
}

.btn {
  margin: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.btn-primary {
  background-color: #4CAF50;
  color: white;
  border: none;
}

.btn-secondary {
  background-color: #f44336;
  color: white;
  border: none;
}

.shipping-details{
  margin-top: 20px;
}
